import Vue from 'vue';

export default {
  register(data) {
    return Vue
      .http
      .post('/v2/office/register', data, { withCredentials: true })
      .then(response => response.data)
  }
};
